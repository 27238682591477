<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-fill"></i> レポート - 反響別推移レポート</h1>
    </header>

    <section class="section">
        <form class="row align-items-end" @submit.prevent="search()">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="condition.department_id"
                    :options="options_department"
                    :required="true"
                ></form-select>
            </div>
            <div class="col-6">
                <label class="form-label">基準日</label>
                <form-input-date
                    v-model="condition.stats_date"
                    :required="true"
                ></form-input-date>
            </div>
            <div class="col-6">
                <button :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </form>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>

        <template v-else-if="transitions.length > 0">
            <div class="d-flex table-like-head">
                <div class="table-like-cell col-6">チャネル</div>
                <div class="grow">
                    <div class="d-flex">
                        <div class="table-like-cell col-4">商談相手</div>
                        <div class="grow">
                            <div class="d-flex">
                                <div class="table-like-cell col-6">顧客要求</div>
                                <div class="table-like-cell col-6">実績</div>
                                <div class="grow">
                                    <div class="d-flex">
                                        <div class="table-like-cell col-6">結果</div>
                                        <div class="table-like-cell col-6">件数</div>
                                        <div class="grow">
                                            <div class="d-flex">
                                                <div class="table-like-cell grow">内訳</div>
                                                <div class="table-like-cell col-8">件数</div>
                                                <div class="table-like-cell col-8">転換率</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex table-like" v-for="level1,key1 of transitions" :key="key1">
                <div class="table-like-cell col-6">{{ level1[0][0].channel.channel_name }}</div>
                <div class="grow">
                    <div class="d-flex" v-for="level2,key2 of level1" :key="key2">
                        <div class="table-like-cell col-4">{{ ContactWith.get(level2[0].contact_with) }}</div>
                        <div class="grow">
                            <div class="d-flex" v-for="transition,index of level2" :key="index">
                                <div class="table-like-cell col-6">{{ RequestedByClient.get(transition.requested_by_client) }}</div>
                                <div class="table-like-cell col-6 text-end">{{ transition.ordered_count + transition.lost_count + transition.prospect_count }}件</div>
                                <div class="grow">
                                    <div class="d-flex">
                                        <div class="table-like-cell col-6">受注</div>
                                        <div class="table-like-cell col-6 text-end">{{ transition.ordered_count }}件</div>
                                        <div class="grow">
                                            <div class="d-flex" v-for="order,o of transition.orders" :key="o">
                                                <div class="table-like-cell grow">{{ order.certainty_name }}</div>
                                                <div class="table-like-cell col-8 text-end">
                                                    <span v-if="order.ordered_count === 0">0件</span>
                                                    <button v-else class="btn btn-link p-0" @click="openModal(order.project_ids)">{{ order.ordered_count }}件</button>
                                                </div>
                                                <div class="table-like-cell col-8 text-end">{{ calcPercent(order.ordered_count, order.sales_count) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="table-like-cell col-6">他社落ち</div>
                                        <div class="table-like-cell col-6 text-end">{{ transition.lost_count }}件</div>
                                        <div class="grow">
                                            <div class="d-flex" v-for="loss,l of transition.losses" :key="l">
                                                <div class="table-like-cell grow">{{ Rank.get(loss.rank) }}</div>
                                                <div class="table-like-cell col-8 text-end">
                                                    <span v-if="loss.lost_count === 0">0件</span>
                                                    <button v-else class="btn btn-link p-0" @click="openModal(loss.project_ids)">{{ loss.lost_count }}件</button>
                                                </div>
                                                <div class="table-like-cell col-8 text-end">{{ calcPercent(loss.lost_count, loss.sales_count) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="table-like-cell col-6">追客</div>
                                        <div class="table-like-cell col-6 text-end">{{ transition.prospect_count }}件</div>
                                        <div class="grow">
                                            <div class="d-flex" v-for="prospect,p of transition.prospects" :key="p">
                                                <div class="table-like-cell grow">{{ Rank.get(prospect.rank) }}</div>
                                                <div class="table-like-cell col-8 text-end">
                                                    <span v-if="prospect.prospect_count === 0">0件</span>
                                                    <button v-else class="btn btn-link p-0" @click="openModal(prospect.project_id)">{{ prospect.prospect_count }}件</button>
                                                </div>
                                                <div class="table-like-cell col-8 text-end"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <p class="alert alert-secondary">※データがありません</p>
        </template>
    </section>

    <div class="modal-backdrop show" v-if="modal_show"></div>
    <div class="modal fade show" tabindex="-1"  style="display:block;" v-if="modal_show">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="activityModalLabel">案件一覧</h3>
                    <button type="button" class="btn-close" @click="modal_show = false"></button>
                </div>
                <div class="modal-body mb-5">
                    <div v-if="modal_projects_list.length">
                        <table class="table table-sm">
                            <thead>
                                <tr class="table-dark table-head">
                                    <th></th>
                                    <th class="text-center w-ms">会社名</th>
                                    <th class="text-center w-ss">客先担当者</th>
                                    <th class="text-center w-ms">事業部</th>
                                    <th class="text-center w-ss">追客担当</th>
                                    <th class="text-center w-ss">ステータス</th>
                                    <th class="text-center w-ss">評価</th>
                                    <th class="text-center w-mm">規格</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="project in modal_projects_list" :key="project.project_id">
                                    <td @click.stop>
                                        <router-link :to="{name: 'ProjectEdit', params: {id: project.project_id}}" class="btn btn-link" target="_blank"><i class="bi bi-box-arrow-up-right"></i></router-link>
                                    </td>
                                    <td>{{ project.customer.company_name }}</td>
                                    <td>{{ project.primary_contact ? project.primary_contact.contact_name : '' }}</td>
                                    <td>{{ project.department ? project.department.department_name : '' }}</td>
                                    <td>{{ project.follow_user ? project.follow_user.user_name : '' }}</td>
                                    <td>{{ project.status_label }}</td>
                                    <td>{{ project.rank_label }}</td>
                                    <td>{{ project.standards_label_all }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <p class="alert alert-secondary">{{ modal_loading ? '検索中です' : '※案件が存在しません' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Project from '@/models/entities/project';
import FormSelect from '@/components/forms/FormSelect';
import FormInputDate from '@/components/forms/FormInputDate';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Department from '@/models/entities/department';
import Rank from '@/models/enums/rank';
import ContactWith from '@/models/enums/contact-with';
import RequestedByClient from '@/models/enums/requested-by-client';

export default {
    name: 'ReportTransition',
    components: {
        InlineLoader,
        FormInputDate,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // 検索フォーム
            options_department: [],
            exists_options_department: false,

            // 検索条件
            condition: {
                department_id: null,
                stats_date: this.$helper.today(),
            },

            // 表示データ
            transitions: [],
            modal_projects_list: [],
            modal_show: false,
            modal_loading: false,

            // Enum
            Rank,
            ContactWith,
            RequestedByClient
        }
    },
    mounted() {
        this.fetchDepartments();
    },
    methods: {
        fetchDepartments() {
            this.loading_count++;

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                // 事業部初期値セット
                if (this.$store.state.auth.department_id) {
                    this.condition.department_id = this.$store.state.auth.department_id;
                } else {
                    this.condition.department_id = this.options_department[0].value;
                }

                this.search();
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        // 検索
        search() {
            // ローディング開始
            this.loading_count++;
            // 表示データ初期化
            this.transitions.splice(0);

            this.$http.get('/report/transition', {
                params: this.condition,
            })
            .then(response => {
                this.transitions = response.data;
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            })
        },
        // モーダル用一覧取得
        openModal(project_ids) {
            this.modal_show = true;
            this.modal_loading = true;
            this.modal_projects_list.splice(0);

            //案件一覧取得
            this.$http.get('/project/ids', {
                params: {project_ids},
            })
            .then((response) => {
                for (let row of response.data) {
                    this.modal_projects_list.push(new Project(row));
                }
            })
            .finally(() => {
                this.modal_loading = false;
            })
        },
        // ％表示
        calcPercent(a, b) {
            if (!b) {
                return "-";
            }

            return (a / b * 100).toFixed(1) + "%";
        }
    }
}
</script>

<style scoped>
.table-like,
.table-like-head {border-top: 1px solid #dee2e6; border-left: 1px solid #dee2e6;}
.table-like-head {background: var(--bs-dark); color: #FFF; font-weight: bold; text-align: center;}
.table-like-cell {padding: 0.2rem 0.5rem; border-bottom: 1px solid #dee2e6; border-right: 1px solid #dee2e6;}
.grow {flex-grow: 1;}
</style>