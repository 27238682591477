import Enum from './enum'

/**
 * 商談相手
 */
class ContactWith extends Enum {
    static CEO = 1;
    static OTHER = 2;

    static values() {
        return {
            [this.CEO]: '社長',
            [this.OTHER]: '担当者',
        }
    }
}

export default ContactWith;
