import Enum from './enum'

/**
 * 顧客要求
 */
class RequestedByClient extends Enum {
    static YES = 1;
    static NO = 0;

    static values() {
        return {
            [this.YES]: 'あり',
            [this.NO]: 'なし',
        }
    }
}

export default RequestedByClient;
